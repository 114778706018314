import { CheckLg, XLg } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
import { dateTimeDif } from 'utils/time';
import { getSelectionColumn } from 'components/table-selection-column';
/** @jsxImportSource @emotion/react */
import { Badge } from 'components/badge';
import { useAuth } from 'providers/auth';
export const PermissionFormatter = ({ permission = [], devicesView }) => {
  const [color, label] = permission.includes('fa')
    ? ['green', 'full']
    : ['gray', 'limited'];

  return (
    <div
      css={css`
        display: flex;
        gap: 0.5rem;
      `}
    >
      {devicesView && <Badge color="success">Devices</Badge>}

      <Badge color={color}>{label}</Badge>
    </div>
  );
};

const ROLE_NAMES = {
  A: { name: 'Admin', color: 'orange' },
  V: { name: 'Vendor', color: 'green' },
  I: { name: 'Installer', color: 'blue' },
  C: { name: 'Company', color: 'red' }
};

export const columns = [
  getSelectionColumn(),
  {
    Header: 'Name',
    accessor: 'name',
    newSorter: true,
    asc: 'atoz',
    desc: 'ztoa',
    pickerDisabled: true,
    Cell: ({ row }) => {
      const { user } = useAuth();
      return (
        <div
          css={css`
            > [role='badge'] {
              font-size: 8px;
              min-height: 12px;
              padding: 0 6px;
              margin: 0 4px;
            }
          `}
        >
          <strong>{row.original.name}</strong>
          {user.user_id == row.original.id && <span> (yourself)</span>}
          {row.original.loggedIn && <Badge color="success">Online</Badge>}
          <br />
          <small>{row.original.email}</small>
        </div>
      );
    }
  },

  {
    Header: 'Last Login',
    accessor: 'last_login_at',
    newSorter: true,
    asc: 'oldestLogin',
    desc: 'newestLogin',
    Cell: ({ cell: { value } }) => <span>{dateTimeDif(value)}</span>
  },
  {
    Header: 'Type',
    Cell: ({ row }) => {
      return (
        <Badge color={ROLE_NAMES[row.original.role]?.color}>
          {ROLE_NAMES[row.original.role]?.name}
        </Badge>
      );
    }
  },
  {
    Header: 'Vendor',
    accessor: 'vendor_name',
    disableSortBy: true
  },
  {
    Header: 'Installer',
    accessor: 'installer_name',
    disableSortBy: true
  },
  {
    Header: 'Company',
    accessor: 'company_name',
    disableSortBy: true
  },
  {
    Header: 'Active',
    accessor: 'active',
    newSorter: true,
    asc: 'activeFirst',
    desc: 'activeLast',
    Cell: ({ cell: { value } }) => {
      return (
        <span
          css={css`
            svg {
              width: 24px;
            }
          `}
        >
          {value ? <CheckLg color="green" /> : <XLg color="red" />}
        </span>
      );
    }
  },
  {
    Header: 'Enabled',
    accessor: 'enabled',
    newSorter: true,
    asc: 'enabledFirst',
    desc: 'enabledLast',
    Cell: ({ cell: { value } }) => {
      return (
        <span
          css={css`
            svg {
              width: 24px;
            }
          `}
        >
          {value ? <CheckLg color="green" /> : <XLg color="red" />}
        </span>
      );
    }
  },

  {
    Header: 'Date Inserted',
    accessor: 'created_at',
    label: 'Date Inserted',
    id: 'created_at',
    newSorter: true,
    asc: 'newest',
    desc: 'oldest',
    Cell: ({ cell: { value } }) => {
      if (value) return <span>{dateTimeDif(value)}</span>;
      else return null;
    }
  },
  {
    disableSortBy: true,
    Header: 'Permissions',
    accessor: 'permission',
    disableSortBy: true,

    Cell: ({ cell: { value }, row: { original } }) => (
      <PermissionFormatter
        devicesView={original.devices_view}
        permission={value || []}
      />
    )
  }
];
