import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Template from 'base/template';
import PageTitle from 'components/page-title';
/** @jsxImportSource @emotion/react */
import Content from 'base/content';
import Breadcrumbs from 'components/breadcrumbs';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useQueryWrapper } from 'utils/ajax';
import Device from 'api/device';
import { useNotFound } from 'hooks/not-found-hook';

import {
  Ping,
  Restore,
  Reboot,
  DateTime,
  Subscription,
  Reset,
  Delete,
  RestoreEvents,
  SyncCheck,
  ServerReport,
  Firmware
} from 'views/devices/tools';
import Throughput from 'views/devices/tools/throughput';
import SmallBadge from 'components/small-badge';
import { Ethernet } from 'react-bootstrap-icons';
import SystemLog from 'views/devices/tools/system-log';
import IsRole from 'components/is-role';
const Main = styled('main')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: max-content;
  width: 100%;
  padding: 24px;
  gap: 2rem;
  &[data-disabled='true'] {
    pointer-events: none;
    > section {
      opacity: 70%;
    }
  }
`;

const DeviceTools = (props) => {
  const { id } = useParams();

  const [device, setDevice] = React.useState(props?.location?.state?.device);
  const query = useQueryWrapper(
    ['device', 'get', id],
    () => Device.get(id),
    {}
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query?.data) return;
    setDevice(query.data);
  }, [query?.data]);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{device?.name}</PageTitle>

        <Breadcrumbs crumbs={props.crumbs} />
        <div
          css={css`
            flex: 1;
            display: flex;
            gap: 0.3rem;
            max-height: 24px;
          `}
        >
          <SmallBadge>
            <Ethernet />
            {device.mac}
          </SmallBadge>
          <SmallBadge>{device.product}</SmallBadge>
        </div>
        {device && (
          <Main>
            <Ping device={device} />

            <Restore device={device} />

            <IsRole restrict="C">
              <ServerReport device={device} />
            </IsRole>
            <IsRole restrict="C">
              <SystemLog device={device} />
            </IsRole>
            <Reboot device={device} />
            <IsRole restrict="C">
              <DateTime device={device} />
            </IsRole>
            <IsRole restrict="C">
              <Subscription device={device} />
            </IsRole>
            <IsRole restrict="C">
              <Firmware device={device} />
            </IsRole>
            <IsRole restrict="C">
              {device.type == 'controller' && <SyncCheck device={device} />}
              {device.type == 'controller' && <RestoreEvents device={device} />}
              {device.type == 'controller' && <Throughput device={device} />}
              <div
                css={css`
                  margin-bottom: 16px;
                  grid-column: span 3;
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
                  gap: 2rem;
                  hr {
                    grid-column: 1 / -1;
                    width: 100%;
                    opacity: 10%;
                    color: black;
                  }
                `}
              >
                <hr />
                {device.type == 'controller' && <Reset device={device} />}
                <Delete device={device} />
              </div>
            </IsRole>
          </Main>
        )}
      </Content>
    </Template>
  );
};

export default DeviceTools;
