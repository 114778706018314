import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  MenuItem,
  MenuItemDelete,
  FloatMenu,
  MenuWrapper,
  MenuItems,
  MenuSeperator
} from 'components/action-menu';
import {
  useReleaseMutate,
  useLockDownMutate,
  useUnlockMutate,
  useLockMutate,
  useAccessMutate,
  useGenericDeleteMutate,
  useReportMutate,
  useDoubleLockMutate,
  useGetStateMutate
} from './actions';
import { dateTimeLocalSQL } from 'utils/time';
import {
  HandThumbsUp,
  Lock,
  Unlock,
  Check2All,
  ShieldLock,
  ExclamationCircle,
  ChevronLeft,
  Exclamation,
  Circle
} from 'react-bootstrap-icons';
import { ReactComponent as DoubleLockIcon } from 'assets/double-lock.svg';
import { Menu } from '@headlessui/react';
import { css } from '@emotion/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { menuButtonStyle } from 'components/action-menu/menu-button';
import { StyledMenuButton } from 'components/action-menu/menu-item-button';
import { useAuth } from 'providers/auth';
import useAlertDialog from 'providers/alert-dialog-provider';
import IsUserAllowed from 'components/is-user-allowed';

/** @jsxImportSource @emotion/react */
export default function ActionMenu({
  noEdit,
  customButton,
  data: door,
  ...props
}) {
  const isACDoor = Boolean(door?.door_token);
  const isOutputDoor = Boolean(door?.port_direction == 'output');
  const history = useHistory();

  const mutateLock = useLockMutate(door);
  const mutateUnlock = useUnlockMutate(door);

  const mutateAccess = useAccessMutate(door);
  const mutateDoubleLock = useDoubleLockMutate(door);

  const mutateLockDown = useLockDownMutate(door);
  const mutateRelease = useReleaseMutate(door);

  const mutateGenericDelete = useGenericDeleteMutate(door);
  const mutateReport = useReportMutate(door);
  const mutateGetState = useGetStateMutate(door);

  let handleEdit = (e) =>
    history.push('/doors/edit/' + door?.token, {
      door,
      desc: door?.Name
    });

  let handleGenericEdit = (e) =>
    history.push('/doors/save/' + door?.id, {
      door,
      desc: door?.Name
    });

  let handleOpenTime = (e) =>
    history.push('/doors/schedule/' + door?.id, {
      door,
      desc: door?.Name
    });

  let handleGenerateReport = (e) =>
    mutateReport.mutate({
      description: 'door',
      timeframe: 'n',
      days: '7',
      output: ['html'],
      recipients: [],
      first_time: dateTimeLocalSQL(),
      time_zone: new Intl.DateTimeFormat(undefined).resolvedOptions().timeZone,
      recurrence: '1',
      type: 'event',
      types: [],
      category: ['access'],
      params: {},
      doors: [door.id]
    });

  const confirm = useAlertDialog();

  const handleLock = async () => {
    if (await confirm({ description: `Lock door  ${door.name}?` }))
      mutateLock.mutate(door.name);
  };

  const handleDoubleLock = async () => {
    if (await confirm({ description: `Double Lock door  ${door.name}?` }))
      mutateDoubleLock.mutate(door.token);
  };

  const handleUnlock = async () => {
    if (await confirm({ description: `Unlock door  ${door.name}?` }))
      mutateUnlock.mutate();
  };

  const handleLockDown = async () => {
    if (
      await confirm({
        description: `Lock down door  ${door.name}?, all activity will be blocked`
      })
    )
      mutateLockDown.mutate(door?.token);
  };

  const handleRelease = async () => {
    if (
      await confirm({
        description: `Release door  ${door.name}?`
      })
    )
      mutateRelease.mutate(door?.token);
  };

  const handleAccess = async () => {
    if (
      await confirm({
        description: `Grant access to door  ${door.name}?`
      })
    )
      mutateAccess.mutate();
  };

  const handleGenericDelete = async () => {
    if (
      await confirm({
        description: `Delete door  ${door.name}?`
      })
    )
      mutateGenericDelete.mutate(door?.id);
  };

  const handleDetails = (e) =>
    history.push('doors/details/' + door?.token, {
      door,
      desc: door.name
    });

  const handleAccessLevels = (e) =>
    history.push('doors/' + door?.id + '/levels', {
      door,
      desc: door.name
    });

  const { enforce } = useAuth();

  const handleGetState = (e) => mutateGetState.mutate(door.id);

  return isACDoor ? (
    <MenuWrapper {...props} css={css``}>
      <DropdownMenu.Root as="div">
        <DropdownMenu.TriggerItem asChild={true}>
          <StyledMenuButton>Operations...</StyledMenuButton>
        </DropdownMenu.TriggerItem>
        <MenuItems>
          <MenuItem label="Access" onClick={handleAccess}>
            <HandThumbsUp />
          </MenuItem>
          <MenuItem label="Lock" onClick={handleLock}>
            <Lock />
          </MenuItem>
          <MenuItem label="Unlock" onClick={handleUnlock}>
            <Unlock />
          </MenuItem>
          {door.isDoubleLock == '1' && (
            <MenuItem label="Double Lock" onClick={handleDoubleLock}>
              <DoubleLockIcon />
            </MenuItem>
          )}
          <MenuItem label="Lock Down" onClick={handleLockDown}>
            <ShieldLock />
          </MenuItem>
          <MenuItem label="Release" onClick={handleRelease}>
            <Check2All />
          </MenuItem>
          <MenuSeperator />
          <MenuItem label="Get State" onClick={handleGetState}>
            <Exclamation />
          </MenuItem>
        </MenuItems>
      </DropdownMenu.Root>

      <MenuSeperator />
      {!noEdit && <MenuItem label="Edit" onClick={handleEdit} />}
      {!noEdit && (
        <IsUserAllowed restrict="C" condition="scheduleView">
          <MenuItem label="Open Times" onClick={handleOpenTime} />
        </IsUserAllowed>
      )}
      {!noEdit && <MenuItem label="Details" onClick={handleDetails} />}
      <MenuItem label="Generate Report" onClick={handleGenerateReport} />

      <IsUserAllowed restrict="C" condition="alView">
        <MenuItem label="Access Levels..." onClick={handleAccessLevels} />
      </IsUserAllowed>
    </MenuWrapper>
  ) : (
    <MenuWrapper {...props}>
      {isOutputDoor && (
        <>
          <MenuItem label="Access" onClick={handleAccess} />
          <MenuItem label="Lock" onClick={handleLock} />
          <MenuItem label="Unlock" onClick={handleUnlock} />
          <MenuSeperator />
          <IsUserAllowed restrict="C" condition="scheduleView">
            <MenuItem label="Open Times" onClick={handleOpenTime} />
          </IsUserAllowed>
          <MenuSeperator />
        </>
      )}
      {!noEdit && <MenuItem label="Edit" onClick={handleGenericEdit} />}
      <MenuItem label="Generate Report" onClick={handleGenerateReport} />
      <MenuSeperator />
      <MenuItem label="Get State" onClick={handleGetState}>
        <Exclamation />
      </MenuItem>
      {!noEdit && <MenuSeperator />}
      {!noEdit && <MenuItemDelete onClick={handleGenericDelete} />}
    </MenuWrapper>
  );
}
