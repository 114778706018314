import { useMutationWrapper } from 'utils/ajax';
import User from 'api/user';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';

export const useDeleteMutate = (user) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['user', 'delete'],
    (id) => User.delete(id),

    {
      successMessage: `User: ${user?.name} deleted successfuly`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id)
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['manage-users']);
      }
    }
  );
};

export const useSendActivationMutate = () => {
  return useMutationWrapper(['user', 'sendActivationEmail'], (id) =>
    User.sendActivationEmail(id)
  );
};

export const useEndSessionMutate = () => {
  const queryClient = useQueryClient();

  return useMutationWrapper(
    ['user', 'end-session'],
    (id) => User.endSession(id),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['manage-users']);
      }
    }
  );
};
